import React from 'react'
export const susds = {
  path: (
    <>
      <g clip-path="url(#clip0_15322_4476)">
        <path
          d="M16.2695 29C23.4492 29 29.2695 23.1797 29.2695 16C29.2695 8.8203 23.4492 3 16.2695 3C9.08983 3 3.26953 8.8203 3.26953 16C3.26953 23.1797 9.08983 29 16.2695 29Z"
          fill="url(#paint0_radial_15322_4476)"
        />
        <path
          d="M11.2977 19.4248H9.44775C9.59877 22.3047 12.0151 24.8082 16.3003 24.8082C20.5667 24.8082 23.285 22.4741 23.285 19.4624C23.285 13.7401 14.6202 14.0602 14.6202 10.7473C14.6202 9.82494 15.3187 8.7332 16.9421 8.7332C18.7544 8.7332 20.8875 10.1449 21.0386 11.952H22.8697C22.6809 9.03436 19.8304 7.00146 16.206 7.00146C12.4493 7.00146 9.61766 9.18496 9.61766 12.2719C9.61766 18.1636 18.2824 17.3918 18.2824 21.0247C18.2824 22.1541 17.584 23.0953 15.8284 23.0953C13.6386 23.0953 11.4299 21.5706 11.2977 19.4248ZM12.8457 11.1802C12.8457 15.6601 21.435 14.7754 21.435 19.5565C21.435 20.9871 20.4911 22.2482 19.3585 22.5682C19.7549 22.1918 20.057 21.3635 20.057 20.573C20.057 16.1495 11.4488 16.7519 11.4488 12.2155C11.4488 10.6908 12.4304 9.4673 13.6574 9.09084C13.1855 9.65553 12.8457 10.3896 12.8457 11.1802Z"
          fill="white"
        />
      </g>
      <defs>
        <radialGradient
          id="paint0_radial_15322_4476"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(16.2695 43.4951) rotate(-90) scale(40.4952)"
        >
          <stop stop-color="#FFEF79" />
          <stop offset="1" stop-color="#00C2A1" />
        </radialGradient>
        <clipPath id="clip0_15322_4476">
          <rect width="26" height="26" fill="white" transform="translate(3.26953 3)" />
        </clipPath>
      </defs>
    </>
  ),
  viewBox: '0 0 33 32',
}
