import React from 'react'
export const usds = {
  path: (
    <>
      <g clip-path="url(#clip0_15322_4484)">
        <path
          d="M16 29C23.1797 29 29 23.1797 29 16C29 8.8203 23.1797 3 16 3C8.8203 3 3 8.8203 3 16C3 23.1797 8.8203 29 16 29Z"
          fill="url(#paint0_radial_15322_4484)"
        />
        <path
          d="M11.0282 19.4177H9.17822C9.32921 22.306 11.7456 24.8167 16.0307 24.8167C20.2971 24.8167 23.0155 22.4758 23.0155 19.4554C23.0155 13.7167 14.3507 14.0376 14.3507 10.7151C14.3507 9.79015 15.0491 8.69522 16.6726 8.69522C18.4849 8.69522 20.618 10.1111 20.769 11.9233H22.6001C22.4114 8.99726 19.5608 6.9585 15.9363 6.9585C12.1798 6.9585 9.3481 9.1483 9.3481 12.2442C9.3481 18.1529 18.0129 17.3789 18.0129 21.0223C18.0129 22.1549 17.3144 23.0988 15.5588 23.0988C13.369 23.0988 11.1603 21.5697 11.0282 19.4177ZM12.5762 11.1493C12.5762 15.6422 21.1655 14.7549 21.1655 19.5499C21.1655 20.9845 20.2216 22.2494 19.0889 22.5702C19.4853 22.1927 19.7874 21.3621 19.7874 20.5692C19.7874 16.133 11.1792 16.7371 11.1792 12.1876C11.1792 10.6585 12.1609 9.43145 13.3879 9.05392C12.9159 9.62022 12.5762 10.3564 12.5762 11.1493Z"
          fill="white"
        />
      </g>
      <defs>
        <radialGradient
          id="paint0_radial_15322_4484"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(16 33.1358) rotate(-90) scale(41.6587)"
        >
          <stop stop-color="#FFD232" />
          <stop offset="1" stop-color="#FF6D6D" />
        </radialGradient>
        <clipPath id="clip0_15322_4484">
          <rect width="26" height="26" fill="white" transform="translate(3 3)" />
        </clipPath>
      </defs>
    </>
  ),
  viewBox: '0 0 32 32',
}
